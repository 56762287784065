<template>
  <div>
    <div class="status_title">全订单状态</div>
    <div class="tab1" v-show="status">
      <div class="status_form">
        <el-form
                size="mini"
                :inline="true"
                :model="formInline"
                :label-position="labelPosition"
                label-width="80px"
                class="demo-form-inline"
        >
<!--          <el-form-item label="作者">-->
<!--            <el-input v-model="formInline.editor" placeholder="作者"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="文章题目">
            <el-input
                    v-model="formInline.title"
                    placeholder="文章题目"
            ></el-input>
          </el-form-item>
<!--          <el-form-item label="处理人">-->
<!--            <el-input-->
<!--                    v-model="formInline.realname"-->
<!--                    placeholder="处理人"-->
<!--            ></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="属性">
            <el-col :span="22">
              <el-select
                      v-model="formInline.attribute"
                      clearable
                      placeholder="属性"
              >
                <el-option label="普通" value="0"></el-option>
                <el-option label="加急" value="1"></el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item label="订单状态">
            <el-col :span="22">
              <el-select
                      v-model="formInline.qikan_state"
                      clearable
                      placeholder="订单状态"
              >
                <el-option label="待处理" value="55"></el-option>
                <el-option label="平台待审" value="0"></el-option>
                <el-option label="平台已审" value="6"></el-option>
                <el-option label="期刊待审" value="2"></el-option>
                <el-option label="期刊已审" value="5"></el-option>
                <el-option label="平台驳回" value="71"></el-option>
                <el-option label="期刊驳回" value="72"></el-option>
                <el-option label="作者已确认" value="47"></el-option>
                <el-option label="出刊邮寄" value="4"></el-option>
              </el-select>
            </el-col>
          </el-form-item>
<!--          <el-form-item label="投稿时间">-->
<!--            <el-date-picker-->
<!--                    type="date"-->
<!--                    value-format="yyyy-MM-dd"-->
<!--                    placeholder="选择日期"-->
<!--                    v-model="formInline.starttime"-->
<!--                    style="width: 130px"-->
<!--            ></el-date-picker>-->
<!--            <span class="to">至</span>-->
<!--            <el-date-picker-->
<!--                    type="date"-->
<!--                    value-format="yyyy-MM-dd"-->
<!--                    placeholder="选择日期"-->
<!--                    v-model="formInline.endtime"-->
<!--                    style="width: 130px"-->
<!--            ></el-date-picker>-->
<!--          </el-form-item>-->

          <el-form-item>
            <el-button type="danger" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="container_table">
        <el-table
                :header-cell-style="headeRowClass"
                :data="tableData"
                stripe
                style="width: 100%"
                :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column prop="title" label="文章标题" sortable>
          </el-table-column>
          <el-table-column prop="editor" label="作者" sortable>
          </el-table-column>
          <el-table-column label="投稿时间" prop="operation_time" sortable>
          </el-table-column>

<!--          <el-table-column prop="realname" label="当前处理人" sortable>-->
<!--          </el-table-column>-->
          <el-table-column prop="state" label="订单状态" sortable>
            <template slot-scope="scope">
              <span v-if="scope.row.state == 55">待处理 </span>
              <span v-if="scope.row.state == 0">平台待审 </span>
              <span v-if="scope.row.state == 6">平台已审</span>
              <span v-if="scope.row.state == 2">期刊待审 </span>
              <span v-if="scope.row.state == 5">期刊已审</span>
              <span v-if="scope.row.state == 71">平台驳回 </span>
              <span v-if="scope.row.state == 72">期刊驳回</span>
              <span v-if="scope.row.state == 47">作者已确认 </span>
              <span v-if="scope.row.state == 4">出刊邮寄</span>
            </template>
          </el-table-column>
          <el-table-column prop="attribute" label="属性" sortable>
            <!--在职是0，离职是1-->
            <template slot-scope="scope">
              <span v-if="scope.row.attribute == 0">普通 </span>
              <span v-if="scope.row.attribute == 1">加急</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                      @click="handleClick(scope.row)"
                      type="text"
                      size="small"
              >查看</el-button
              >
<!--              <el-button @click="receive(scope.row)" type="text" size="small"-->
<!--              >更换处理人</el-button-->
<!--              >-->
            </template>
          </el-table-column>
        </el-table>
        <div class="orderPage">
          <el-pagination
                  class="fy"
                  small
                  @size-change="handleSizeChange"
                  :page-size="pagesize"
                  :page-sizes="[10, 20, 30]"
                  layout="total, sizes, prev, pager, next, jumper"
                  @current-change="current_change"
                  :total="total"
                  background
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!--  详情-->
    <div class="tab2" v-show="!status">
      <div class="articleTitle">{{ article.title }}</div>
      <div class="xs">
        <span class="author">作者：{{ article.editor }}</span
        ><span>期刊：{{ article.name }}</span>
      </div>
      <p class="bt">附件</p>
      <div class="file" @click="downloadAttach">
        <i class="el-icon-paperclip"></i><a>点击下载附件</a>
      </div>
      <p class="bt">摘要</p>
      <div class="articletext">
        {{ article.summary }}
      </div>
      <p class="bt">关键词</p>
      <div class="articletext">
        {{ article.keyword }}
      </div>
      <p class="bt">备注</p>
      <div class="articletext">
        {{ article.remarks }}
      </div>
      <el-button type="danger" @click="back()" class="btn">返回</el-button>
    </div>

    <!--弹窗-->
    <el-dialog
            title="更换为"
            :visible.sync="dialog"
            width="503px"
            :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" :model="form">
          <el-form-item>
            <el-select
                    filterable
                    v-model="form.region"
                    placeholder="请选择员工"
                    style="width: 80%"
            >
              <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.realname"
                      :value="item.id"
              >
                <span style="float: left">{{ item.realname }}</span>
                <span style="float: right; color: red; font-size: 13px"
                >处理中：{{ item.geshu }}件</span
                >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="danger" @click="staffchange()" :loading="loading"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { searchOrder,fwsorderalls } from '@/API/orderstatus'
  import { changeStaff } from '@/API/orderstatus'
  import { staffList } from '@/API/toDoOrder'
  import {checksteps,checktrace, checkarticle} from "../../../API/QKD/detail";
  import steps from '../../DePenDent/components/steps'
  import trace from '../../DePenDent/components/trace'
  import articlecont from "../../DePenDent/components/articlecont";

  export default {


    data () {
      return {
        id: "",//订单的id，点击更改员工时赋值
        labelPosition: 'right',//标签label对齐方式
        status: true,//控制显示详情还是显示列表
        loading: false,
        id: "",//供应商id
        form: {//选择员工表单数据
          name: '',
          region: ''
        },
        dialog: false, //默认不显示弹窗
        //表单基本数据
        formInline: {
          id: "",
          page: "",
          limit: "",
          starttime: "",
          endtime: "",
          editor: "",
          title: "",
          attribute: "",
          realname: "",
          qikan_name: "",
          qikan_state: ""
        },
        activeName: 'first',
        value3: '',
        total: 0, //默认数据总数
        pagesize: 8, //每页的数据条数

        currentPage: 1, //默认开始页面
        tableData: [//订单列表数据
        ],
        article: {//文章详情数据

        },

        options: []
      }
    },
    // mounted () {
    //   this.onSubmit()
    // },
    methods: {
      // 下载附件
      downloadAttach () {

        let Path = this.article.content
        console.log(Path);
        downloadFile(Path)
      },
      //
      onSubmit () {
     //   debugger;
        let staff_id = localStorage.getItem('UserId')
        this.formInline.id = localStorage.getItem('FuWuId');
        this.formInline.limit = this.pagesize
        this.formInline.page = this.currentPage
        console.log(this.formInline);
        fwsorderalls(this.formInline.id, this.formInline.page,
                this.formInline.limit, this.formInline.starttime, this.formInline.endtime, this.formInline.editor,
                this.formInline.title, this.formInline.attribute, this.formInline.realname, this.formInline.qikan_name, this.formInline.qikan_state,staff_id).then(res => {
          if (res.data.code == 0) {
            this.tableData = res.data.data
            this.total = res.data.total
          }
        })
      },
      //点击查看显示详情页，隐藏列表页
      handleClick (tab) {
        let wid = tab.works_id;
        this.$router.push({ path: "/Services/orderStatusDetail", query: { id: 4, wid: wid } })

      },
      //点击更换处理人
      change (tab, event) {
        console.log(tab, event);
        this.status = false

      },
      staffchange () {
        this.dialog = false
        changeStaff(this.id, this.form.region).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: '更改成功',
              type: 'success'
            });
            this.onSubmit()
          }


        })

      },
      // 更改表头样式
      headeRowClass ({
                       row,
                       column,
                       rowIndex,
                       columnIndex
                     }) {
        if (rowIndex === 0) {
          return 'background-color: #F2F2F2;color: #666666;font-weight: 700;'
        }
      },
      // 分页
      current_change: function (currentPage) {
        this.currentPage = currentPage;
        this.onSubmit()
      }, handleSizeChange (val) {
        console.log(val);
        this.pagesize = val;
        this.onSubmit()
      },
      //弹窗
      receive: function (row) {
        //显示弹框
        this.dialog = true;
        //获取订单id
        this.id = row.works_id

      },
      selectStaff () {
        staffList(localStorage.getItem("FuWuId")).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.options = res.data.data
          }

        })
      },
      //点击返回列表页
      back () {
        this.status = !this.status
      }
    },
    mounted () {

      this.onSubmit(),
              this.selectStaff()
    }
  }

</script>

<style>
  .orderPage {
    display: flex;
    justify-content: center;
  }
  .status_title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-weight: 500;
    color: #141414;
    border-bottom: 1px solid #e6e6e6;
  }

  .status_form {
    padding-top: 10px;
  }

  .to {
    font-size: 14px;
    color: #333;
    margin: 0 5px;
  }
  .articleTitle {
    height: 80px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
    line-height: 80px;
    text-align: center;
  }
  .xs {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
  }
  .bt {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  .xs .author {
    margin-right: 10px;
  }
  .file {
    width: 50%;
    height: 40px;
    background: #f7f7f7;
    border-radius: 4px;
    border: 1px solid #cccccc;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .file span {
    font-size: 14px;
  }
  .articletext {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin: 10px;
  }
  .btn {
    width: 100px;
    background: red;
    border: red;
    margin-top: 20px;
  }
</style>